<template>
  <div class="c-row">
    <div class="ui-m-4 ui-mt-8 c-col">
      <!-- Título -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <span class="c-text-h2 c-text-gray-400">
            Editar Grupo
          </span>
        </div>
      </div>

      <!-- Nome -->
      <div class="c-row ui-mb-8">
        <div class="c-col">
          <info-card
            data-cy="grupoInfo"
            :items="[
              { label: 'Nome', value: selectedGroup.name },
              { label: 'Criado em', value: selectedGroupCreatedAt },
            ]"
          />
        </div>
      </div>

      <p-form ref="form">
        <ExpandableRow
          v-for="permissionGroup of permissionsGroups"
          :key="permissionGroup.context"
          :title="permissionGroup.context"
          class="c-text-b2 c-text-gray-300"
          :data-cy="`permission-${permissionGroup.context}`"
        >
          <!-- Table title -->
          <div class="c-d-flex ui-justify-between">
            <div class="simple-table-row">
              Nome
            </div>
            <div class="simple-table-row">
              Descrição
            </div>
          </div>

          <!-- Separador -->
          <div class="separator" />

          <!-- Linhas da tabela -->
          <div
            v-for="permission of permissionGroup.permissions"
            :key="permission._id"
          >
            <!-- Dados -->
            <div class="c-d-flex ui-justify-between ui-items-center ui-mb-4">
              <div class="simple-table-row">
                <c-toggle
                  :value="hasPermission(permission._id)"
                  @input="e => updatePermission(permission._id, e)"
                  class="ui-mr-4 ui-flex-shrink-0"
                  :data-cy="`permission-toggle-${permission.id}`"
                />
                <div class="c-d-flex ui-flex-col">
                  <span>{{ permission.name }}</span>
                </div>
              </div>

              <div class="simple-table-row">
                <div class="c-d-flex ui-flex-col c-text-b3">
                  <span>{{ permission.description }}</span>
                  <span class="wrap-parentheses">{{ permission.id }}</span>
                </div>
              </div>
            </div>

            <!-- Separador -->
            <div class="separator" />
          </div>
        </ExpandableRow>

        <!-- Botões -->
        <div class="c-row c-horizontal-center ui-mt-4">
          <div class="c-col-1/4 c-d-flex ui-justify-end">
            <c-button
              full
              outline
              style="max-width: 164px;"
              data-cy="cancelarGrupoButton"
              @click="$router.push({ name: 'groups' })"
              :disabled="isFormSending"
            >
              Cancelar
            </c-button>
          </div>

          <div class="c-col-1/4">
            <c-button
              full
              type="submit"
              style="max-width: 164px;"
              data-cy="salvarGrupoButton"
              @click="updatePermissions"
              :loading="isFormSending"
            >
              Salvar
            </c-button>
          </div>
        </div>
      </p-form>
    </div>
  </div>
</template>

<script>
import { CButton, CToggle } from '@estrategiahq/coruja-web-ui';
import InfoCard from '../../components/info-card';
import PForm from '../../components/p-form';
import ExpandableRow from '../../components/expandable-row';

const fullDateTimeFmt = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' });

export default {
  components: {
    ExpandableRow,
    InfoCard,
    CButton,
    PForm,
    CToggle,
  },
  data () {
    return {
      groupId: '',
      isFormSending: false,
      selectedGroup: {},
      permissions: [],
      selectedPermissions: [],
    };
  },
  mounted () {
    this.groupId = this.$route.params.id;
    this.init();
  },
  computed: {
    selectedGroupCreatedAt () {
      return this.selectedGroup.created_at ? fullDateTimeFmt.format(new Date(this.selectedGroup.created_at)) : null;
    },
    permissionsGroups () {
      const permissionsAsObj = this.permissions.reduce((acc, permission) => {
        const context = permission.context;
        if (acc[context] == null) acc[context] = [];
        acc[context].push(permission);
        return acc;
      }, {});

      return Object.entries(permissionsAsObj)
        .map(([context, permissions]) => ({ context, permissions }))
        .filter(p => p.context !== '*');
    },
  },
  methods: {
    async init () {
      const [currentGroup, permissions] = await Promise.all([
        this.$s.group.get(this.groupId),
        this.$s.permission.getAll(),
      ]);

      this.selectedGroup = currentGroup;
      this.selectedPermissions = currentGroup.permissions.map(p => p._id);

      this.permissions = permissions;
    },
    hasPermission (permissionId) {
      return this.selectedPermissions.includes(permissionId);
    },
    updatePermission (permissionId, shouldAdd) {
      if (shouldAdd) {
        this.selectedPermissions.push(permissionId);
      } else {
        this.selectedPermissions = this.selectedPermissions.filter(p => p !== permissionId);
      }
    },
    async updatePermissions () {
      const selectedPermissions = this.selectedPermissions;
      if (selectedPermissions.length <= 0) {
        this.$toast.show({ type: 'error', text: 'Selecione ao menos uma permissão' });
        return;
      }

      this.isFormSending = true;
      try {
        await this.$s.group.updatePermissions(this.groupId, this.selectedPermissions);
        this.$router.push({ name: 'groups' });
        this.$toast.show({ type: 'success', text: 'Permissões salvas com sucesso' });
      } catch (e) {
        this.$toast.show({ type: 'error', text: 'Erro ao salvar as permissões' });
      } finally {
        this.isFormSending = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-table-row {
  display: flex;
  align-items: center;
  flex: 1;
  word-break: break-word;
}

.wrap-parentheses {
  &::before {
    content: "(";
  }

  &::after {
    content: ")";
  }
}
</style>
